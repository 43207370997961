// NewPage.js
import React from 'react';
import DateTimeList from './DateTimeList';

class NewPage extends React.Component {
    render() {
        const exampleDates = [
            '2023-09-10T10:30:00Z',
            '2023-09-11T12:45:00Z',
            '2023-09-12T15:15:00Z',
          ];
        
          return (
            <div>
              <h1>Example Date-Time List</h1>
              <DateTimeList dates={exampleDates} />
            </div>
          );
        }
    }

    export default NewPage;