import logo2 from './mariosoniccalendar.png';
import './App.css';
import './Login.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import NewPage from './NewPage';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          CLOKKEN
        </p>
        <p>
          Get on your clokken way!
        </p>
        <img src={logo2} className="App-logo" alt="logo2" />

        <Router>
      <div>
        <Link to="/new-page">
          <button>Go to New Page</button>
        </Link>

        <Routes>
          <Route path="/new-page" element={<NewPage />} />
        </Routes>
      </div>
    </Router>

    <div className="Login">
      <form >
        <div>
          <label htmlFor="username">Username:</label>
          <input
            type="text"
            id="username"
            required
          />
        </div>
        <div>
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            required
          />
        </div>
        <button type="submit">Login</button>
      </form>
    </div>

      </header>

    </div>
  );
}

export default App;
