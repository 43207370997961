import React from 'react';
import './DateTimeList.css';

const DateTimeList = ({ dates }) => {
  return (
    <div className="date-time-list">
      {dates.map((date, index) => (
        <div className="date-time-item" key={index}>
          {new Date(date).toLocaleString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
              })}
        </div>
      ))}
    </div>
  );
}

export default DateTimeList;